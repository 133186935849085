<nav class="bg-white border-b border-app-bg sticky top-0 z-30 w-full">
  <div class="">
    <div class="flex items-center w-full">
      <div class="flex items-center justify-between w-[22.5rem] px-4  lg:border-r lg:border-app-bg">
        <a
          routerLink="/"
          (click)="onClickLogo()"
          class="cursor-pointer text-xl font-bold  h-16 object-center object-cover flex items-center lg:justify-center  py-2">
          <img
            src="assets/logo/shamolima.png"
            class="notPreview cursor-pointer"
            alt="Shamolima Logo" />
        </a>
        <button
          class="cursor-pointer"
          (click)="toggleMobileSideBar()"
          id="toggleSidebarMobile"
          aria-expanded="true"
          aria-controls="sidebar"
        >
          <img src="assets/svg/bar.svg" class="notPreview cursor-pointer" alt="bar">
        </button>
      </div>
      <div class="flex items-center space-x-3 justify-end w-full mr-2">
        <!-- Search mobile -->
        <button
          (click)="onClickSearchOnMobile()"
          id="toggleSidebarMobileSearch"
          type="button"
          class="lg:hidden text-gray-500 hover:text-gray-900 hover:bg-gray-100 p-2 rounded-lg">
          <span class="sr-only">Search</span>
          <!-- Search icon -->
          <i class="fas fa-search"></i>
        </button>
        <button matTooltip="Logout" (click)="onClickLogOut()"
                class="lg:hidden text-gray-500 hover:text-gray-900 hover:bg-gray-100 p-2 rounded-lg">
          <img loading="lazy" class="notPreview" src="assets/svg/logout.svg" alt="logout">
        </button>

        <button class="flex items-center" [matMenuTriggerFor]="menu" (click)="isProfileOpened = !isProfileOpened">
          <div
            id="dropdownDefaultButton"
            class="hidden lg:flex space-x-2 items-center font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600"
            type="button">
            <div
              class="w-8 h-8 overflow-hidden rounded-full border border-gray-400 object-center object-cover">
              <img
                class="w-full h-full notPreview"
                [src]="image | image"
                alt="profile-img" />
            </div>
            <span class="text-gray-500">{{ name }}</span>
            <i
              *ngIf="!isProfileOpened"
              class="fas fa-caret-down ml-2 text-not-black"></i>
            <i
              *ngIf="isProfileOpened"
              class="fas fa-caret-up ml-2 text-not-black"></i>
          </div>
        </button>
        <mat-menu #menu="matMenu" xPosition="before" class="w-full">
          <button
            (click)="onClickLogOut()" mat-menu-item>
            <div class="flex space-x-2 items-center">
              <img
                class="notPreview"
                src="./assets/svg/user-inactive.svg"
                alt="" />
              <span>Logout</span>
            </div>
          </button>
          <div class="flex items-center justify-center text-sm px-4 mt-2"
               *ngIf="environment.userUrl == 'https://user-service-dev.snztech.world'">
            <span class="text-not-black">Version:</span>
            <span
              class="px-2 py-0.1 rounded-full ml-1 bg-green text-white font-semibold">5.2.5</span>
          </div>
        </mat-menu>
      </div>
    </div>
  </div>
</nav>
